/*import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";*/
import { CountUp } from "./plugins/countUp.js";
import { MetaSwiper } from "./meta-settings.js";

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

if (header.classList.contains("fixed")) {
  document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 0) {
      header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
      header.classList.remove("scrolled");
    }
  });
}

AOS.init({
  useClassNames: true,
  initClassName: "animated",
  duration: 1000,
  offset: 50,
});

const vh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

vh();

window.onresize = () => vh();


function setSameHeight(elArr) {
  let minHeight = 0;
  let arr = document.querySelectorAll(elArr);
  if (arr) {
    function setHeight() {
      arr.forEach((el) => {
        if (minHeight < el.clientHeight) {
          minHeight = el.clientHeight;
        }
      });
      arr.forEach((el) => (el.style.minHeight = minHeight + "px"));
    }
    window.addEventListener("resize", setHeight());
  }
}

let userAgent = navigator.userAgent.toLowerCase();
let safari12 = /version\/12.*safari/gi.test(userAgent);
let iphone = /iphone/gi.test(userAgent);
let iphoneIOs13 = /iphone.*version\/13.*safari/gi.test(userAgent);
let ipadIOs13 = /mac.*version\/13.*safari/gi.test(
  navigator.userAgent.toLowerCase()
);
let ipad = /ipad/gi.test(userAgent);
let isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
if (safari12) $("body").addClass("safari-12");
if (iphone) $("body").addClass("iphone");
if (ipad) $("body").addClass("ipad");
if (isTouch) $("body").addClass("isTouch");

// menu handlers

function setCountUp(selector) {
  const counts = Array.from(document.querySelectorAll(`${selector}`));
  if (counts) {
    const defaultOptions = {
      separator: "",
      enableScrollSpy: true,
      scrollSpyRunOnce: true,
    };

    let idNumber = 1;

    counts.forEach((count) => {
      const id = `count-up-${idNumber}`,
        value = parseFloat(count.innerHTML);

      let optionsFromDataAttr = $(count).data();

      for (const key in optionsFromDataAttr) {
        if (optionsFromDataAttr[key] === "") {
          optionsFromDataAttr[key] = true;
        }
      }

      count.id = id;
      const countItem = new CountUp(
        id,
        value,
        Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
      );
      idNumber++;

      new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) countItem.start();
        });
      }).observe(count);
    });
  }
}

window.scrollToEl = function scrollToEl(targetID) {
  let config = [...targetID.split("|")];
  let duration = +config[1] && +config[1] != "" ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] != "" ? +config[2] : 0;
  let target = config[0];
  if ($(target).length) {
    $("html, body").animate(
      {
        scrollTop: $(target).offset().top - paddingTop,
      },
      duration
    );
    return false;
  }
};
/*onclick=scrollToEl("#get-in-touch-section|800|150")*/

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

//fix mobile menu height because of bottom system navigation panel

if (isMobileMenuEnable) {
  let navbar = document.querySelector(".navbar-nav");
  if (navbar) {
    let vh = window.innerHeight * 0.01;
    navbar.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      navbar.style.setProperty("--vh", `${vh}px`);
    });
  }
}
let readMorePopup = document.querySelector(".read-more-popup-content");
if (readMorePopup) {
  let vh = window.innerHeight * 0.01;
  readMorePopup.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    readMorePopup.style.setProperty("--vh", `${vh}px`);
  });
}

// toggle menu handler
window.menuToggle = function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".navbar-nav").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  if (scrollLock.getScrollState()) {
    scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"));
  } else {
    if (!document.querySelector(".navbar-nav.active")) {
      scrollLock.enablePageScroll();
    }
  }
};
window.closeMenu = function closeMenu() {
  $(".menu-toggle").removeClass("active");
  $(".navbar-nav").removeClass("active");
  $(".header-close-wrapper").removeClass("active");
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};

//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}

$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);

// open hide areas list item info
$(".areas-section-list-title").click(function () {
  $(".areas-section-list-title.active").next().slideUp();
  $(".areas-section-list-title.active").removeClass("active");
  let $this = $(this);
  $(this)
    .toggleClass("active")
    .next(".areas-section-list-content")
    .slideToggle({
      queue: false,
      complete: function () {
        if (!$(this).is(":visible")) {
          $this.removeClass("active");
        }
      },
    });
});
$(".jobs-sectors-title").click(function () {
  $(this)
    .toggleClass("active")
    .next(".jobs-sectors-content")
    .slideToggle({ queue: false });
});

let tsSwiperWr = document.querySelectorAll(".ts-swiper-wr");
tsSwiperWr.forEach((el) => {
  if (el) {
    let swiperBlock = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
    let swiper = MetaSwiper(swiperBlock, {
      slidesPerView: 1,
      spaceBetween: 30,
      autoHeight: true,
      effect: "fade",
      loop: slidesCount > 1,
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      on: {
        afterInit: function () {
          if (iphone || ipad) {
            $(".ts-card-content-text").each((ind, el) => {
              new SimpleBar(el, {
                autoHide: false,
              });
            });
          }
        },
      },
    });

    if (!safari12 && !iphoneIOs13) {
      if (!ipadIOs13 && !isTouch) {
        let options = {
          root: document,
          rootMargin: "0px",
          threshold: 1.0,
        };
        let cb = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
               swiper.autoplay?.start();
            } else {
               swiper.autoplay?.stop();
            }
          });
        };
        let observer = new IntersectionObserver(cb, options);
        observer.observe(swiperBlock);
      }
    } else {
      window.addEventListener("scroll", function () {
        if (
          $(window).scrollTop() >
            $(swiperBlock).offset().top - $(window).height() * 0.93 &&
          $(window).scrollTop() <
            $(swiperBlock).offset().top +
              $(swiperBlock).height() -
              $(window).height() * 0.25
        ) {
          swiper.autoplay.start();
        } else {
          swiper.autoplay.stop();
        }
      });
    }
  }
});

let ossSwiperWr = document.querySelectorAll(".oss-swiper-wr");
ossSwiperWr.forEach((el) => {
  if (el) {
    let swiperBlock = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
    MetaSwiper(swiperBlock, {
      spaceBetween: 30,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        551: {
          slidesPerView: 2,
          loop: slidesCount > 2,
        },
        993: {
          slidesPerView: 3,
          loop: slidesCount > 3,
        },
      },
      on: {
        afterInit: function () {
          let ossCards = [...document.getElementsByClassName("oss-card")];
          ossCards.forEach((card, ind) => {
            card.addEventListener("mousemove", function (e) {
              let btn = this.querySelector(".oss-card-btn");
              if (btn) {
                btn.style.left = e.layerX + "px";
                btn.style.top = e.layerY + "px";
              }
            });
          });
        },
      },
    });
  }
});

let valueSwiperWr = document.querySelectorAll(".value-swiper-wr");
valueSwiperWr.forEach((el) => {
  if (el) {
    let swiperBlock = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
    MetaSwiper(swiperBlock, {
      spaceBetween: 34,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        993: {
          slidesPerView: 2,
          loop: slidesCount > 2,
        },
        1100: {
          slidesPerView: 3,
          loop: slidesCount > 3,
        },
        1600: {
          slidesPerView: 4,
          loop: slidesCount > 4,
        },
      },
    });
  }
});
//sets countUp
setCountUp(".count-up");

let teamSwiperWr = document.querySelectorAll(".team-swiper-wr");
teamSwiperWr.forEach((el) => {
  if (el) {
    let swiperBlock = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
    let swiper = MetaSwiper(swiperBlock, {
      spaceBetween: 30,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        280: {
          slidesPerView: 1,
          loop: slidesCount > 1,
        },
        701: {
          slidesPerView: 2,
          loop: slidesCount > 3,
        },
        1024: {
          slidesPerView: 3,
          loop: slidesCount > 3,
        },
      },
    });

    if ((!safari12 && !iphoneIOs13) || (!ipadIOs13 && !isTouch)) {
      let cb = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            swiper.autoplay.start();
          } else {
            swiper.autoplay.stop();
          }
        });
      };
      let observer = new IntersectionObserver(cb);
      observer.observe(swiperBlock);
    }
  }
});

// nice-select

$(".jobs-search-select, .jobs-head-select").niceSelect();

$(".jobs-head-select").on("change", function () {
  localStorage.setItem("niceFilter", $(this).val());
  if ($(this).val().toLowerCase() === "nearest") {
    $(this).parent().find(".jobs-search-input").fadeIn();
  } else {
    $(this).parent().find(".jobs-search-input").fadeOut();
  }
});
$("body").on("click", ".jobs-list .jobs-card", function () {
  localStorage.setItem("moveBack", "moved");
});

$(".jobs-search-select, .jobs-head-select").each(function (ind, el) {
  let filter = localStorage.getItem("niceFilter");
  let moveBack = localStorage.getItem("moveBack");
  let text = $(el).find(`[value=${filter}]`).text().trim();
  let select = document.querySelector(".jobs-head-select");
  if (filter != null && moveBack == "moved") {
    select.value = filter;
    $(el).trigger("change");
    $(el).find("option").eq(0).attr("data-display", text);
    $(".jobs-head-select").niceSelect("update");
    localStorage.removeItem("niceFilter");
    localStorage.removeItem("moveBack");
  } else if (filter == null && moveBack == "moved") {
    localStorage.removeItem("moveBack");
  }
});

$(".jobs-search-select, .jobs-head-select").on("change", function () {
  console.log(this.value);
});

if (document.querySelector(".nice-select")) {
  const selectSimplbar = document.querySelectorAll(".nice-select .list");
  selectSimplbar.forEach((select) => {
    const div = document.createElement("div");
    const content = select.innerHTML;
    select.innerHTML = "";
    div.setAttribute("data-simplebar", "");
    div.innerHTML = content;
    select.appendChild(div);
  });
}
// end nice-select
$(".footer .input-wr input").on("input", function () {
  if ($(this).val() != "") {
    $(this).parent().addClass("active");
  } else {
    $(this).parent().removeClass("active");
  }
});

let partnersSwiperWr = document.querySelectorAll(".partners-swiper-wr");
partnersSwiperWr.forEach((el) => {
  if (el) {
    let swiperBlock = el.querySelector(".swiper");
    let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
    MetaSwiper(swiperBlock, {
      slidesPerView: "auto",
      spaceBetween: 20,
      speed: 800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        280: {
          loop: slidesCount > 1,
        },
        500: {
          loop: slidesCount > 2,
        },
        768: {
          loop: slidesCount > 3,
        },
        992: {
          loop: slidesCount > 4,
        },
      },
    });
  }
});

let detailSwiperWr = document.querySelectorAll(".detail-swiper-wr");
detailSwiperWr.forEach((el) => {
  if (el) {
    let swiperBlock = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
    MetaSwiper(swiperBlock, {
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      direction: "horizontal",
      mousewheel: false,
      breakpoints: {
        568: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: "auto",
          direction: "vertical",
          mousewheel: true,
        },
      },
      on: {
        afterInit: function () {
          if (iphone || ipad) {
            $(".jobs-card-text").each((ind, el) => {
              if (window.innerHeight < 651) {
                new SimpleBar(el, {
                  autoHide: false,
                });
              }
            });
          }
        },
      },
    });
  }
});

let offBoxSwiperWr = document.querySelectorAll(".off-box-swiper-wr");
offBoxSwiperWr.forEach((el) => {
  if (el) {
    let swiperBlock = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
    const swiper = MetaSwiper(swiperBlock, {
      slidesPerView: 3,
      speed: 800,
      loop: slidesCount > 3,
      spaceBetween: 10,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        280: {
          slidesPerView: 1,
          loop: slidesCount > 1,
          autoHeight: true,
        },
        601: {
          slidesPerView: 2,
          loop: slidesCount > 1,
        },
        769: {
          loop: slidesCount > 2,
        },
        1024: {
          loop: slidesCount > 3,
        },
        1200: {
          spaceBetween: 25,
        },
        1800: {
          spaceBetween: 65,
        },
      },
      on:{
        slideChange: function(){
          $('.off-box-text-hidden').slideUp();
        }
      }
    });

    let offBoxArr = $(".off-box");
    if (offBoxArr.length) {
      setSameHeight(".off-box");
      offBoxArr.each((idx, box) => {
        let btn = $(box).find(".off-box-btn");
        $(btn).click(function () {
          $(this).prev().stop(true, true).slideToggle();
        });
      });
    }
  }
});

let relatedSwiperWr = document.querySelectorAll(".related-swiper-wr");
relatedSwiperWr.forEach((el) => {
  if (el) {
    let swiperBlock = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
    MetaSwiper(swiperBlock, {
      spaceBetween: 50,
      speed: 800,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        320: {
          loop: slidesCount > 1,
          slidesPerView: 1,
          spaceBetween: 25,
        },
        500: {
          loop: slidesCount > 2,
          slidesPerView: 2,
          spaceBetween: 25,
        },
        769: {
          loop: slidesCount > 3,
          slidesPerView: 3,
          spaceBetween: 25,
        },
        1200: {
          spaceBetween: 50,
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      on: {
        breakpoint: function () {
          let breakpoint = this.currentBreakpoint;
          let loop = this.passedParams.breakpoints[breakpoint].loop;
          if (loop) {
            this.loopCreate();
            nextEl.classList.remove(
              "swiper-button-lock",
              "swiper-button-disabled"
            );
            prevEl.classList.remove(
              "swiper-button-lock",
              "swiper-button-disabled"
            );
          } else {
            this.loopDestroy();
          }
        },
      },
    });
  }
});

$("body").on("click", ".close-read-more-popup", function () {
  $(".read-more-popup").fadeOut();
});
$(".show-read-more-popup").click(function () {
  let popupInfo = $(this).parent().find(".read-more-popup-text").html();
  let readMorePopup = $(".read-more-popup");
  readMorePopup.fadeIn({
    start: function () {
      $(this).find(".read-more-popup-text").html(popupInfo);
    },
  });
});

let whiteCardsSwiperWr = document.querySelectorAll(".white-cards-swiper-wr");

whiteCardsSwiperWr.forEach((el) => {
  let swiper;
  let isSwiper = false;
  $(window).on("resize", function () {
    if (el && window.innerWidth <= 768 && !isSwiper) {
      isSwiper = true;
      let swiperBlock = el.querySelector(".swiper");
      let nextEl = el.querySelector(".swiper-button-next");
      let prevEl = el.querySelector(".swiper-button-prev");
      el.querySelector(".swiper-wrapper").classList.add(
        "white-cards-list-initialized"
      );
      let slidesCount = swiperBlock.querySelectorAll(".swiper-slide").length;
      swiper = new Swiper(swiperBlock, {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 800,
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          320: {
            loop: slidesCount > 1,
            slidesPerView: 1,
            spaceBetween: 25,
          },
          500: {
            loop: slidesCount > 2,
            slidesPerView: 2,
            spaceBetween: 25,
          },
          769: {
            loop: slidesCount > 3,
            slidesPerView: 3,
            spaceBetween: 25,
          },
          1200: {
            spaceBetween: 25,
            slidesPerView: 3,
          },
        },
        on: {
          breakpoint: function () {
            let breakpoint = this.currentBreakpoint;
            let loop = this.passedParams.breakpoints[breakpoint].loop;
            if (loop) {
              this.loopCreate();
              nextEl.classList.remove(
                "swiper-button-lock",
                "swiper-button-disabled"
              );
              prevEl.classList.remove(
                "swiper-button-lock",
                "swiper-button-disabled"
              );
            } else {
              this.loopDestroy();
            }
          },
        },
      });
    } else if (isSwiper && window.innerWidth > 768) {
      isSwiper = false;
      swiper.destroy(true, true);
      el.querySelector(".swiper-wrapper").classList.remove(
        "white-cards-list-initialized"
      );
    }
  });
  $(window).trigger("resize");
});

if (ipad || iphone) {
  $(".jobs-card-text").each((ind, el) => {
    new SimpleBar(el, {
      autoHide: false,
    });
  });
}

$("body").on("change", "input[type=file]", function () {
  $(this).closest("label").addClass("upload-file-success");
});

$(".jobs-sectors-content ul li a").click(function () {
  $(".jobs-sectors-content ul li a").removeClass("active");
  $(this).addClass("active");
});

$(".cookie-terms-section table").each((idx, table) => {
  /*Just added wrapper to table inserted from admin panel*/
  $(table).before(
    `<div class="table-wrapper table-wrapper-initialized"></div>`
  );
  $(table).prev().append(table);
});

$(".areas-card").hover(
  function () {
    $(this).addClass("active");
    $(this).find(".areas-card-text-content").stop(true, false).slideDown();
  },
  function () {
    $(this).removeClass("active");
    $(this).find(".areas-card-text-content").stop(true, false).slideUp();
  }
);

/* sectors tabs*/

const sectors = document.getElementsByClassName("all-sectors-container")[0];
if (sectors) {
  const tabBtn = sectors.querySelectorAll(".all-sectors-tab__btn");
  const tabItem = sectors.querySelectorAll(".all-sectors-tab__item");
  const contentItem = sectors.querySelectorAll(".all-sectors-content__item");
  /*solutionsAccordion.style.minHeight = `${solutionsAccordion.clientHeight}px`;*/

  tabBtn.forEach((btn, i) => {
    btn.addEventListener("click", () => {
      const isActive = btn.classList.contains("active");

      const activeTabBtn = sectors.querySelector(
        ".all-sectors-tab__btn.active"
      );
      const activeTabItem = sectors.querySelector(
        ".all-sectors-tab__item.active"
      );
      const activeContentItem = sectors.querySelector(
        ".all-sectors-content__item.active"
      );

      if (activeTabBtn) {
        activeTabBtn.classList.remove("active");
        activeTabItem.classList.remove("active");
        activeContentItem.classList.remove("active");
      }

      if (isActive) {
        if (i !== 0) {
          btn.classList.remove("active");
          tabItem[i].classList.remove("active");
          contentItem[i].classList.remove("active");
          tabBtn[0].classList.add("active");
          tabItem[0].classList.add("active");
          contentItem[0].classList.add("active");
        }
      } else {
        btn.classList.add("active");
        tabItem[i].classList.add("active");
        contentItem[i].classList.add("active");
      }
    });
  });
}
let ltJobsSwiperWr = document.querySelectorAll(".lt-jobs-swiper-wr");
ltJobsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = el.querySelectorAll(".swiper-slide").length;
    let swiper = MetaSwiper(swiperEl, {
      slidesPerView: 3,
      spaceBetween: 20,
      speed: 800,
      threshold: 10,
      /*autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },*/
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        200: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1281: {
          spaceBetween: 33,
          slidesPerView: 3,
        },
      },
    });
  }
});
$(".job-card .btn-link").click(function (e) {
  e.preventDefault();
});
/* end of sectors tabs*/

let tmVacSwiperWr = document.querySelectorAll(".tm-vac-swiper-wr");
tmVacSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector(".swiper");
    let nextEl = el.querySelector(".swiper-button-next");
    let prevEl = el.querySelector(".swiper-button-prev");
    let slidesCount = el.querySelectorAll(".swiper-slide").length;
    if (slidesCount > 1) {
      swiperEl.classList.add("gradient-bottom");
    }
    let swiper = MetaSwiper(swiperEl, {
      slidesPerView: "auto",
      spaceBetween: 15,
      speed: 800,
      threshold: 10,
      direction: "horizontal",
      loop: slidesCount > 1,
      /*autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },*/
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        641: {
          direction: "vertical",
        },
      },
    });
  }
});

